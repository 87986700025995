:root {  
    /*Margins & Paddings*/
    --padding: 1em;
    --margin: 1em;
    --vertical-padding: 1em; /* must NOT be a percentage value */
    --vertical-margin: 1em;  /* must NOT be a percentage value */   
    
     /*Breakpoints*/
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 800px;
    
     /*General*/
    --container-max-width: 1300px;
    --fixed-header-height: 80px;
   
    /* Slick */
    --slick-slider-height: 60vh;
    
    /* change settings for headings */
    --text-color: #222;
    --body-bg: #fff;
    --main-color: #222;
    --accent-color: #2864C5;
    --effect-color: #222;
    --header-text-color: #fff;
    --header-bg-color: var(--main-color);
    --footer-bg-color: var(--main-color);
    --footer-text-color: #ccc;
    --menu-bg-color: inherit;
    --menu-bg-hover: var(--accent-color);
    --menu-text-color: inherit;
    --menu-text-hover-color: #fff;
    --menu-li-a-padding: 10px 15px;
    --link-color: var(--accent-color);
    --link-hover-color: var(--effect-color);

    /* Typo */
    --scale-factor: 1.618;
    --base-font-size: 15px;
    --base-line-height: 1.5;
    --scale-factor-m: var(--scale-factor);
    --scale-factor-s: 1.418;
    --scale-factor-xs: 1.218;
    /* Fonts */
    --base-font-family: 'Open Sans', sans-serif;
    --alternative-font-family: 'Open Sans', sans-serif; 
}

@import "/cssmodules/normalize.css";
@import "/cssmodules/basic_styling.css";
@import "/cssmodules/flex_grid.v1.1.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.v1.2.css";
@import "/cssmodules/magnific-popup.css";
@import "/fonts/Open Sans/all.css"; /* 300, 300, 300i, 300i, 400, 400, 400, 400i, 400i, 400i, 700, 700i */
@import "/fonts/Quicksand/all.css"; /* 400 */
@import "/fontmodules/Muli.css";
/*@import "/cssmodules/animate.css";*/

/*--------------------------------------------
General
---------------------------------------------*/
img {
    max-width: 100%;
    height: auto;
}

.flex > * > img {
    flex: 0 0 auto;
}
/* cross-browser fix, obs! gäller endast för bilder i div utan klass*/
.flex > * > img:not([class]) {
    width: 100%;
}

.container {
    max-width: var(--container-max-width);
    padding: 0.5em;
    width: 100%;
}

.container-lrg {
    max-width: var(--container-max-width);
    margin: 0 auto;
}

html {
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
    height:100%;
}

body {
    background: url('/theme/intendit/graphics/bg3.jpg') no-repeat fixed center top;
    font-family: var(--base-font-family);
    font-weight: 400;
    color: var(--text-color);
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
}

h1, h2, h3, h4 {
    font-family: var(--alternative-font-family);
}

h1 {
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
}

.site h1 {
    padding: 0 0 0 .5rem;
}

.container h1 {
    margin: 2rem 0.8rem;
}

.sida {
    background-color: rgba(255,255,255,.6);
}

h2 {
    font-size: calc(1.2 * var(--scale-factor)rem);
    font-weight: 700;
}

h3 {
    font-size: 1.2rem;
}

a {
    color: var(--link-color);
    transition: 0.5s ease;
}

a:hover {
    color: var(--link-hover-color);
}

.markerLabels {
    overflow: visible !important;
}

.fa-map-marker {
    background: url(/extensions/local/intendit/intenditExt/web/black/map-marker.svg);
    background-size: cover;
    padding: 10px;
    background-position: center;
}

/*--------------------------------------------------------
Header
---------------------------------------------------------*/
header {
    padding: var(--padding);
}

header .container {
    padding :0 0.5em;
}

header a {
    text-decoration: none;
}

.logo {
    padding: 0;
}

.logo a {
    font-size: 0;
}

.menu li {
    margin:0;
}

.menu li a {
    padding: var(--menu-li-a-padding);
    background: var(--menu-bg-color);
    color: var(--menu-text-color); 
    text-transform: uppercase;
}

.menu li a:nth-last-child{
    padding: 0;
    margin: 0;
    background: none;
    border: 1px solid
}
.menu li a:nth-last-child a:hover {
    background: none !important;
}

.menu li a:hover, .menu .active a {
    background: var(--menu-bg-hover);
    color: #fff; 
}

/**** Dropdown ****/

.menu .dropdown ul {
    z-index: 100;
    border-bottom: none;
}

.menu li:hover ul {
    margin-left: 0;
}

.menu .sub-menu-items li a { 
    margin: 0; 
    background: #fff;
    color: #222;
    min-width: 350px;
}

.menu.menu .sub-menu-items li a:hover { 
    background: var(--accent-color); 
    color: #fff;
}


/**** Leftmenu ****/

.submenu ul {
    margin-top: 2.8rem;
    padding: 0;
}

.submenu ul li {
    list-style-type: none;
    padding: var(--padding);
    border-bottom: 1px solid #eee;
    background: #fff;
} 

.submenu ul li a  {
    text-decoration: none;
    color: var(--effect-color);
}

.submenu li a:hover, .submenu .active a {
    color: var(--accent-color);
}

/*--------------------------------------------------------
Slider startsida
---------------------------------------------------------*/


/*Slick arrows*/
.slick-prev, .slick-next {width:100px; height:100px; border:none;position:absolute; z-index:1; }  
.slick-prev {left:0px;} .slick-next {right:0px;}
.slick-prev:before, .slick-next:before { font-size:100px; color:#fff; opacity:0.9; font-family: Quicksand, sans-serif; }
.slick-prev:before {content:"<";} .slick-next:before {content:">";}


.topslider {
    margin-bottom: 0;
}

.topslider .slick-slide {  
    height: var(--slick-slider-height)
}

.topslider .slick-slide {
    background-size: cover;
    background-position: center center;
    display: flex !important;
    align-items: center;
    justify-content: center;
} 

.topslider .slick-title {
    text-align:center;
    color: white;
    font-family: var(--alternative-font-family);
    font-size: 2rem;
    font-weight: 100;
    background: rgba(255,255,255,0.3);
    padding: 2rem;
    /*text-shadow: 1px 1px 2px rgba(0,0,0,0.5);*/
}

/*.topslider .slick-title {
    color:white;
    min-height:45vh;
    background:var(--main-color);
    padding:2em;
    max-width:33%;
    width:100%;
}*/


.slick-dots {
    bottom:0px;
}

.slick-dots li button:before {
    font-size:0rem;
    opacity:1.0;
    color: var(--accent-color);
    width:40px;
    height:8px;
}

.slick-dots li.slick-active button:before {
    background:var(--accent-color);
    display:block;
}

.slick-dots li {
    background: var(--main-color);
    border-radius:0%;
    margin:0 2px;
    width:40px;
    height:8px;
    z-index: 99999;
}

/* Content-startpage | Blocks
---------------------------------------------------------*/

.block-2  {
    position:relative;
    z-index:10;
    overflow:visible;
    margin-top: -260px !important;
    padding:2em 0;
    z-index: 9999;
}

.block-2 .container {
    padding: 0;
}

.inner-content h1 {
    font-size: 2rem;
}

.block-2 .container .inner-content {
    max-width: var(--container-max-width);
    margin:0 auto;
    padding:2em;
    position:relative;
    text-align:center;
    border-bottom:#eee solid 1px;
    background: #fff;
}

.block-2 .container .inner-content:after {
    content: "";
    position: absolute;
    z-index: -1;
    -webkit-box-shadow: 0 0 40px rgba(0,0,0,0.8);
    box-shadow: 0 0 40px rgba(0,0,0,0.8);
    bottom: 0px;
    width: 80%;
    height: 50%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    left: 10%;
    right: 10%;
}

.block-1 {

}

.block-2  {
    margin: 0 auto;
    text-align: center;
}

.block-3 {
    background: url('/theme/intendit/graphics/kontaktbanner-bg.png');
    color: #222;
    padding: 2rem;
}


.logobanner {
    background: #fff;
    text-align: center;
}

.logobanner img {
    max-height: 150px;
    width: auto;
}

/* Boxar / Zoomboxar
-------------------------------------------------*/

.boxes {
    margin-bottom: 2rem;
}

.boxes a {
    text-decoration: none;
    color: #fff;
}

.boxes a:hover {
    color: #ccc;
}

.boxbutton, button, input[type=reset], input[type=submit] {
    border: 1px solid #fff;
    border-radius: 2px;
    padding: 0.5rem;
}

.boxwrapper {
    min-height: 25vh;
    background-size: cover;
    background-position:center;
    box-shadow:inset 1px 1px 1px 1px rgba(255,255,255,1);
}

.zoombox .boxwrapper {
    background-size: 0% 0%;
    position: relative;
    overflow: hidden;
    text-decoration: none;
}

.boxwrapper h2 {
    color: #fff;
    margin: 0;
    text-align:center;
    text-transform: uppercase;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
    z-index: 3;
    font-weight: 400;
    margin-bottom: 1rem;
}

.zoombox .boxwrapper[style]:before, .boxwrapper:after, .boxwrapper figure img, .boxwrapper figure figcaption {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.zoombox .boxwrapper[style]:before {
    content: '';
    background-image: inherit;
    background-size: cover;
    background-position: center center;
    transition: transform .65s ease-in 0s;
        
    box-shadow:inset 1px 1px 1px 1px rgba(255,255,255,1);
}

.zoombox .boxwrapper figure img {
    transition: transform .65s ease-in 0s;
}

.zoombox .boxwrapper:after {
    content: '';
    background: rgba(0,0,0,0);
    transition: background .65s ease-in 0s;
    z-index: 2;
        box-shadow:inset 1px 1px 1px 1px rgba(255,255,255,1);
}

.zoombox .boxwrapper:hover:after {
    background: rgba(0,0,0,0.3);
    transition: background .65s ease-out 0s;
}

.zoombox .boxwrapper[style]:hover:before, .boxwrapper:hover figure img {
    transform: scale(1.3);
    transition: transform .65s ease-out 0s;
}

.zoombox .box-teaser {
    z-index: 101;
    opacity: 0;
    text-align: center;
    transition: 0.5s ease;
    color: white;
}

.zoombox .boxwrapper:hover .box-teaser {
    opacity:1;
}

/* listing items
---------------------------------------------------------*/
.listing-item {
    margin-bottom:1.5em;
    border-bottom: 1px solid #ccc;
}

.listing-item p:first-of-type {
    padding-top:0;
    margin-top:0;
}

.listing-item h2 {
    margin-bottom: 1rem;
}

.pagination {
}

.pagination ul {
    float: left;
    clear: both;
    display: block;
    margin: 8px 0;
    padding: 0;
    border: 1px solid #DDD;
}

.pagination ul li {
    float: left;
    list-style-type: none;
    border-right: 1px solid #DDD;
    padding: 4px 6px;
}

.pagination ul li:last-child {
    border-right: none;
}

.pagination ul li.active {
    font-weight: bold;
    background-color: var(--link-color);
}

.pagination ul li.active a {
    color:white;
}

.pagination ul li a {
    text-decoration: none;
}
    
/* Content
---------------------------------------------------------*/

.content.margin > h1, .content.margin > .art-img {
    margin-left:0 ;
}

.content h1 {
    margin-bottom:0;
}

.art-content p:first-of-type {
    margin-top:0;
}


/* Contact
---------------------------------------------------------*/

.contact-block-l, .contact-block-2 {
}

.kontakt svg {
    margin-right: 0.5rem;
}


/* Footer
---------------------------------------------------------*/

footer {
    background: var(--footer-bg-color);
    color: var(--footer-text-color);
}

footer .container-lrg {
    text-align: center;
    padding: var(--padding);
}

footer a {
    text-decoration:none;
    color: var(--footer-text-color);
}

footer svg.fa-icon {
    fill: var(--footer-text-color);
    height: calc(1 * var(--scale-factor)rem);
    transition: ease-in 0.5s;
}

footer svg.fa-icon:hover {
    fill: var(--accent-color);
}

/*Google maps*/
.map-canvas {
    height: 30vh;
}

.map-canvas img {
    max-width: none;
}
 
/*Intendit footer*/
.created-by {
    background: var(--main-color);
    padding: 0.5em 0;
    text-align: right;
}

.created-by .container, .intenditfooter {
    padding: 0;
    margin: 0 auto;
}

.created-by .container .intenditfooter, .created-by .container .intenditfooter a  {
     color: color(var(--main-color) lightness(70%));
}


/*------------------------------------------
Sticky footer
-------------------------------------------*/
.site {
    display: flex;
    flex-direction: column;
    height: 100%;
}

main {
    flex: 1 0 auto;
    min-height: 1%; /* IE fix */
}

header, footer {
    flex: none;
}

footer .contactinfo p {
    margin:0;
}


/* general
---------------------------------------------------------*/

.boltform *, .boltform input[type] {
    border-radius: 0px;
}

.boltform {
    padding: 1rem;
}

.boltform textarea {
    min-height: 150px;
}

.boltform input, .boltform textarea {
    width: 100%;
    color: #222;
    background: #777;
    border: none;
}

.boltform .boltforms-row label.required:after {
    content: " *";
}

.kontaktbanner .boltforms-row {
    float: left;
    width: 33.3%;
    padding-right: 10px;
}

.kontaktbanner .boltforms-row:nth-child(n+4) {
    width: 100%;
}
button[type=submit], input[type=submit] {
    background: var(--accent-color);
    color: #fff;
    transition: 0.2s ease-in 0s;
    border: none;
    padding: 0.8rem;
    float: right;
    margin: 0;
}

button[type=submit]:hover, input[type=submit]:hover {
    background: var(--effect-color);
    color: #fff;
}



/* Media Queries
---------------------------------------------------------*/

@media (max-width: var(--breakpoint-m)) {
      
    h1 {
        font-size: calc(1 * var(--scale-factor-m) * var(--scale-factor-m)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-m)rem);
    }
}

@media (max-width: var(--breakpoint-s)) {
    
    h1 {
        font-size: calc(1 * var(--scale-factor-s) * var(--scale-factor-s)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-s)rem);
    }
    
    .menu li a, .menu li li a {
        background: #fff;
    }
    
    .sub-menu-items ul li {
        border-left: none;
    }
    
    .kontaktbanner .boltforms-row {
        width: 100%;
    }
    
    .intenditfooter {
        text-align: center;
    }
}

.localeswitcher.active {
    display:none;
}
